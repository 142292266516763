<script>
import { h } from 'vue';
import { mapGetters } from 'vuex';
import { get } from 'lodash-es';
import marked from 'marked';

import { MODALS_MODULE_NAME, HANDLE_MODAL_HASH_EVENT, POP_MODALS } from '~coreModules/modals/js/modals-store';
import { getBaseLinkAttributes } from '~coreModules/core/js/url-utils';

import { getModalHashEvents } from '~modules/modals/js/modal-constants';
import { interpolateMarkdown } from '~modules/contentful/js/contentful-utils';

export default {
    name: 'BaseMarkdown',
    props: {
        source: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'div',
        },
        linkClasses: {
            type: Array,
            default: () => ['o-text--link', 'is-inline'],
        },
    },
    data() {
        return {
            boundMarkdownLinkListener: null,
        };
    },
    computed: {
        ...mapGetters(['appBaseUrl']),
    },
    render() {
        const { appBaseUrl } = this;
        const $routerPush = this.$router.push;
        const $dispatch = this.$store.dispatch;

        const renderer = new marked.Renderer();
        const hostname = get(this, '$userState.hostname', {});
        let isExternal;
        let to;
        let modalHashEvent;
        let isHashOnlyLink;
        let linkTarget;
        let linkRel;
        const source = interpolateMarkdown(this.source, this.$root);

        // add class to links so we can intercept the clicks, to allow for client-side routing
        renderer.link = (href, title, text) => {
            ({
                isExternal,
                to,
                linkTarget,
                linkRel,
                modalHashEvent,
                isHashOnlyLink,
            } = getBaseLinkAttributes({
                hostname,
                href,
                appBaseUrl,
            }, getModalHashEvents(this.$runtimeConfig.features)));

            const linkClasses = this.linkClasses.toString().replace(',', ' ');

            return `
                <a class="js-markdown-link ${linkClasses}" href="${to}" target="${linkTarget}" rel="${linkRel}">
                    ${text}
                </a>`;
        };

        let html;

        try {
            html = marked(source, { renderer });
        } catch (e) {
            this.$logger.warn('Error parsing markdown: ', source);
            html = source;
        }

        return h(this.tag, {
            class: ['c-markdown js-markdown'],
            innerHTML: html,
            style: this.$attrs.style,
            onClick(e) {
                if (!e.target.matches('a.js-markdown-link') || isExternal) return;
                e.preventDefault();
                const href = e.target.getAttribute('href');

                // launch a modal if a hash matching one of our predefined modals exists
                if (modalHashEvent) {
                    /* if the path is not a hash only link, push the route, let App.vue handle the hash modal */
                    if (!isHashOnlyLink) {
                        $routerPush({
                            path: href,
                        });
                        return;
                    }

                    // if the path is not changing, push the hash modal without changing the
                    // URL, to avoid back button issues
                    $dispatch(`${MODALS_MODULE_NAME}/${HANDLE_MODAL_HASH_EVENT}`, modalHashEvent);
                    return;
                }

                if (href) {
                    $routerPush({
                        path: href,
                    });

                    $dispatch(`${MODALS_MODULE_NAME}/${POP_MODALS}`, { omitPageviewEvent: true });
                }
            },
        });
    },
};
</script>

<style lang="scss">
    .c-markdown {
        word-break: break-word;

        p {
            &:not(:last-child) {
                margin-bottom: $nu-spacer-1pt5;
            }
        }

        ul,
        ol {
            margin-left: $nu-spacer-3;
        }

        ul {
            list-style-type: disc;
            margin-left: $nu-spacer-3;

            li {
                @include caption;
                margin-bottom: $nu-spacer-1pt5;
            }
        }

        ol {
            list-style-type: decimal;
        }
    }
</style>
